import { BaseApiUrl } from 'src/app/shared/base_api/base-api-urls';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { CookiesService } from 'src/app/shared/services/cookies.service';
import { FreeTrialUserDetailsInterface } from '../models/free-trial-user-details.interface';

@Injectable({
  providedIn: 'root'
})
export class FreeTrialService {

  public isFreeTrialAccountRegistered$ = new BehaviorSubject<boolean>(false);

  public isFreeTrialUser = signal<boolean>(false);
  public isFreeTrialExpired = signal<boolean>(false);
  public totalFreeTrialPagesConsumed$ = signal<number>(0);

  public companyName: string;
  public username: string;
  public userId: string;
  public userrole: string;

  constructor(
    private http: HttpClient,
    private cookiesService: CookiesService,
  ) { }

  public getUserDetailsFromSessionOrCookie(): void {
    this.companyName = this.cookiesService.getCookieByName("companyName");
    this.userrole = this.cookiesService.getCookieByName("userRole");
    this.username = this.cookiesService.getCookieByName("userName");
    this.userId = this.cookiesService.getCookieByName("userId");

  }


  public isValideBussinessEmail(email: string): Observable<any> {

    const fd = new FormData();
    fd.append('email', email);
    return this.http.post(BaseApiUrl.IsValideBussinessEmail, fd, { responseType: 'text' });
  }

  public registerForFreeTrialUser(freeTrialUser: FreeTrialUserDetailsInterface,): Observable<any> {

    let baseUrl = `${window.location.hostname}/free-trial/login`;

    const fd = new FormData();
    fd.append('full_name', freeTrialUser.name);
    fd.append('contact_no', freeTrialUser.contactNumber);
    fd.append('email', freeTrialUser.email);
    fd.append('company_name', freeTrialUser.companyName);
    fd.append('document_type', freeTrialUser.documentType);
    fd.append('trial_url', baseUrl);

    return this.http.post(BaseApiUrl.RegisterFreeTrialUser, fd, { responseType: 'text' });
  }



  public saveFreeTrialUserPreferences(industry: string, role: string, expectedDocumentForProcessing: string): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    fd.append('industry', industry);
    fd.append('trial_user_role', role);
    fd.append('future_document_process_no', expectedDocumentForProcessing);

    return this.http.post(BaseApiUrl.SaveFreeTrialUserPreferences, fd, { responseType: 'text' });
  }

  public requestForFreeTrialUpgrade(): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    fd.append('user_name', this.username);

    return this.http.post(BaseApiUrl.RequestForFreeTrialUpgrade, fd, { responseType: 'text' });
  }

  public getTotalPagesConsumed(): Observable<any> {

    this.getUserDetailsFromSessionOrCookie();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    fd.append('collection_name', 'Invoices');
    fd.append('user_name', this.username);
    fd.append('role', this.userrole);

    return this.http.post(BaseApiUrl.GetTotalPagesConsumed, fd);
  }
}
