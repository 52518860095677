import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Dropdown01Component } from './dropdown01/dropdown01.component';
import { Dropdown02Component } from './dropdown02/dropdown02.component';
import { Dropdown03Component } from './dropdown03/dropdown03.component';
import { Dropdown04Component } from './dropdown04/dropdown04.component';
import { ClickoutsideDirective } from './directives/clickoutside.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dropdown00Component } from './dropdown00/dropdown00.component';
import { Dropdown05Component } from './dropdown05/dropdown05.component';
import { Dropdown06Component } from './dropdown06/dropdown06.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { Dropdown07Component } from './dropdown07/dropdown07.component';
import { Dropdown08Component } from './dropdown08/dropdown08.component';
import { Dropdown09Component } from './dropdown09/dropdown09.component';
import { Dropdown10Component } from './dropdown10/dropdown10.component';
import { Dropdown11Component } from './dropdown11/dropdown11.component';
import { Dropdown12Component } from './dropdown12/dropdown12.component';



@NgModule({
  declarations: [
    ClickoutsideDirective,
    Dropdown00Component,
    Dropdown01Component,
    Dropdown02Component,
    Dropdown03Component,
    Dropdown04Component,
    Dropdown05Component,
    Dropdown06Component,
    Dropdown07Component,
    Dropdown08Component,
    Dropdown09Component,
    Dropdown10Component,
    Dropdown11Component,
    Dropdown12Component,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
  ],
  exports: [
    Dropdown00Component,
    Dropdown01Component,
    Dropdown02Component,
    Dropdown03Component,
    Dropdown04Component,
    Dropdown05Component,
    Dropdown06Component,
    Dropdown07Component,
    Dropdown08Component,
    Dropdown09Component,
    Dropdown10Component,
    Dropdown11Component,
    Dropdown12Component,
    ClickoutsideDirective
  ]
})
export class DropdownModule { }
