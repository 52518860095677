import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';
import { forkJoin, switchMap, of } from 'rxjs';

export const loginGuard: CanActivateFn = (route, state) => {

  //dependency 
  const authenticationService = inject(AuthenticationService);
  const notificationService = inject(NotificationService);
  const router = inject(Router);

  //login observables
  const isLoggedIn$ = authenticationService.isLoggedIn$();


  return forkJoin([isLoggedIn$])
    .pipe(
      switchMap(([isLoggedIn$]) => {
        if (isLoggedIn$) {
          return of(true);
        } else {
          router.navigate(['/login']);
          notificationService.showInfo("", "You Are Unauthorized. Please Login!");
          return of(false);
        }
      }
      )
    )
};
