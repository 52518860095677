import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollComponent } from './components/scroll/scroll.component';
import { MaterialModule } from './modules/material.module';
import { GlobalSpinnerComponent } from './components/global-spinner/global-spinner.component';
import { GeneralPopupComponent } from './components/pop-ups/general-popup/general-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Error404Component } from './components/error-pages/error404/error404.component';
import { Error401Component } from './components/error-pages/error401/error401.component';
import { CommentHistoryPopupComponent } from './components/pop-ups/comment-history-popup/comment-history-popup.component';
import { ApprovePopupComponent } from './components/pop-ups/Approved-popup/approve-popup.component';
import { DateSchedulerPopupComponent } from './components/pop-ups/date-scheduler-popup/date-scheduler-popup.component';
import { DragDirective } from './directives/dragDrop.directive';
import { SaveAndApprovePopupComponent } from './components/pop-ups/save-and-approve-popup/save-and-approve-popup.component';
import { ColumnNameRenamePopupComponent } from './components/pop-ups/column-name-rename-popup/column-name-rename-popup.component';
import { CustomeFieldPopupComponent } from './components/pop-ups/custome-field-popup/custome-field-popup.component';
import { RejectedPopupComponent } from './components/pop-ups/rejected-popup/rejected-popup.component';
import { ContineousDrawingConsentPopupComponent } from './components/pop-ups/contineous-drawing-consent-popup/contineous-drawing-consent-popup.component';
import { ReconciliationApprovedPopupComponent } from './components/pop-ups/reconciliation-approved-popup/reconciliation-approved-popup.component';
import { SharedPipeModule } from './pipes/shared-pipe.module';
import { FreeTrialUpgradeBarComponent } from './components/layout/free-trial-upgrade-view/free-trial-upgrade-bar/free-trial-upgrade-bar.component';
import { SharedDirectiveModule } from './directives/shared-directive.module';
import { GlobalPageProgressBarComponent } from './components/global-page-progress-bar/global-page-progress-bar.component';
import { DropdownModule } from '../modules/style-library/dropdown/dropdown.module';
import { SkeltonSkinLoaderComponent } from './components/skelton-skin-loader/skelton-skin-loader.component';
import { GlobalActivityLogPopupComponent } from './components/global-activity-log-popup/global-activity-log-popup.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MenuModule } from '../modules/style-library/menu/menu.module';



@NgModule({
  declarations: [
    ScrollComponent,
    GlobalSpinnerComponent,
    GeneralPopupComponent,
    Error404Component,
    Error401Component,
    CommentHistoryPopupComponent,
    DateSchedulerPopupComponent,
    ApprovePopupComponent,
    SaveAndApprovePopupComponent,
    ColumnNameRenamePopupComponent,
    CustomeFieldPopupComponent,
    DragDirective,
    RejectedPopupComponent,
    ContineousDrawingConsentPopupComponent,
    ReconciliationApprovedPopupComponent,
    FreeTrialUpgradeBarComponent,
    GlobalPageProgressBarComponent,
    SkeltonSkinLoaderComponent,
    GlobalActivityLogPopupComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    SharedPipeModule,
    SharedDirectiveModule,
    DropdownModule,
    MenuModule,
    NgxPaginationModule,
  ],
  exports: [
    SharedDirectiveModule,
    ScrollComponent,
    GlobalSpinnerComponent,
    GeneralPopupComponent,
    FreeTrialUpgradeBarComponent,
    GlobalPageProgressBarComponent,
    SkeltonSkinLoaderComponent,
    GlobalActivityLogPopupComponent,
  ],
})
export class SharedModule { }
