import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { InputIconDirective } from './input-icon.directive';
import { AutoResizeDirective } from './auto-resize.directive';



@NgModule({
  declarations: [
    TooltipDirective,
    InputIconDirective,
    AutoResizeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TooltipDirective,
    InputIconDirective,
    AutoResizeDirective
  ]
})
export class SharedDirectiveModule { }
