import { Router } from '@angular/router';
import { CookiesService } from 'src/app/shared/services/cookies.service';
import { CompnayLogoTextDisplayService } from './../../../services/compnay-logo-text-display.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss']
})
export class Error401Component implements OnInit, OnDestroy {

  public organizationName = environment.companySpecificSettings.organizationName;
  public titleText = `${environment.companySpecificSettings.organizationName}:Unauthorized`;
  public isCompanyLogo = environment.companySpecificSettings.errorPageLogo;
  public logo_sm = environment.companySpecificSettings.logo_sm.url;
  public logo_md = environment.companySpecificSettings.logo_md.url;
  public logo_md_width = environment.companySpecificSettings.logo_md.width;

  constructor(
    private title: Title,
    public compnayLogoTextDisplayService: CompnayLogoTextDisplayService,
    public cookiesService: CookiesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.setPageTitle(this.titleText);

    if (this.cookiesService.checkCookie('accessToken')) {
      this.cookiesService.deleteCookie('accessToken');
    }

    if (this.cookiesService.checkCookie('refreshToken')) {
      this.cookiesService.deleteCookie('refreshToken');
    }
    this.cookiesService.deleteAllCookies();
  }

  public setPageTitle(title: string): void {
    if (environment.companySpecificSettings.isCompanySpecificSettings) {
      this.title.setTitle(title);
    } else {
      let hostName = window.location.hostname;
      this.title.setTitle(hostName);
    }
  }

  public navigateToLoginPage(): void {
    console.log('mayur');

    this.cookiesService.deleteAllCookies();
    this.router.navigate(['/login']);
  }

  handleTooltipClick() {
    // Implement your logic here
    console.log('Tooltip clicked!');
    // You can add any other logic you want to execute when the tooltip is clicked
  }

  ngOnDestroy(): void {

  }

}
