
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalSpinnerService } from './shared/services/global-spinner.service';
import { Subject, delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public isGlobalSpinner: boolean = false;

  public unsubscriber$ = new Subject();


  constructor(
    public globalSpinnerService: GlobalSpinnerService,
  ) {

  }


  ngOnInit() {
    if (environment.companySpecificSettings.isCompanySpecificSettings) {
      this.changeAppTitle(environment.companySpecificSettings.organizationName);
    }
    this.changeAppFavicon(environment.companySpecificSettings.favIconUrl);

    this.getGlobalSpinnerState();
  }


  // To change FavIcon: Only complete url is required.
  // example:https://www.google.com/favicon.ico
  // it will not work from src or asset directory.
  public changeAppFavicon(favIconUrl: string): void {
    if (environment.companySpecificSettings.isFavIcon) {
      let favIcon: HTMLLinkElement = document.querySelector('#favicon-id') as HTMLLinkElement;
      favIcon.href = favIconUrl;
    } else {
      let favIcon: HTMLLinkElement = document.querySelector('#favicon-id') as HTMLLinkElement;
      favIcon.href = 'https://';
    }

  }

  public changeAppTitle(title: string): void {
    let appTitle: HTMLElement = document.getElementById("appTitle-id") as HTMLElement;
    appTitle.innerText = title;

  }

  // Here delay(0) is important otherwise it will create problem.
  public getGlobalSpinnerState(): void {
    this.globalSpinnerService.isGlobalSpinner$
      .pipe(delay(0))
      .subscribe(result => {
        // console.log("isGlobalSpinner", result);
        this.isGlobalSpinner = result;
      })
  }




  ngOnDestroy(): void {
    this.unsubscriber$.next(null);
    this.unsubscriber$.complete();
  }

}
