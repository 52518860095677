import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu01Component } from './menu01/menu01.component';
import { TieredMenuComponent } from './tiered-menu/tiered-menu.component';
import { OverlayModule } from '@angular/cdk/overlay';



@NgModule({
  declarations: [
    Menu01Component,
    TieredMenuComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  exports: [
    Menu01Component,
    TieredMenuComponent
  ]
})
export class MenuModule {
  static ClickoutsideDirective: any[] | Type<any>;
}
