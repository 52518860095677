import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }


  //////////////////////////////// Cookie ////////////////////////////////

  // Cookies are usually represented as a name-value pair, separated by an equal sign, and multiple cookies are separated by semicolons.

  //  A cookie can be up to 4 KB, including its name and values, cookies that exceed this length are trimmed to fit. Also, each time the browser requests a page to the server, all the data in the cookie is automatically sent to the server within the request.

  // Note:  Don't store sensitive data such as a password or credit card information in cookies since it could potentially be manipulated by the malicious user.

  // Path: if / the cookies will be sent for all paths
  // HttpOnly: if true, the cookie cannot be accessed from within the client-side javascript code.
  // Secure: cookie has to be sent over HTTPS
  // SameSite: Lax, Strict, None or not set. Instructs browser whether or not to sent cookie in case of cross-site requests
  // Domain: The domain for which the cookie is set and can be sent to.
  // Max-Age: Time to live of the cookie


  public setCookie(cookieName: string, value: string, daysToLive: number, path = "/") {
    /* Sets the max-age attribute so that the cookie expires after the specified number of days */
    // Encode value in order to escape semicolons, commas, and whitespace.
    // if path is not provided then by dafault it take the current route and later on it is difficult to delete those cookie.
    let isSecureUrl = this.detectSecureUrl();

    const expires = new Date();
    expires.setDate(expires.getDate() + daysToLive);

    let cookieString = `${cookieName}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=${path};`;

    if (isSecureUrl) {
      cookieString = `${cookieString}Secure`
    }

    document.cookie = cookieString;
  }

  public getCookieByName(cookieName: string) {
    // Split cookie string and get all individual name=value pairs in an array
    let cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
      if (cookieName == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  }

  public checkCookieByNameAndSet(cookieName: string) {
    // Get cookie using our custom function
    let cookieDetails = this.getCookieByName(cookieName);

    if (cookieDetails != "") {
      alert("Welcome again, " + cookieDetails);
    } else {
      cookieDetails = prompt("Please enter your Cookie name:");
      if (cookieDetails != "" && cookieDetails != null) {
        // Set cookie using our custom function
        this.setCookie("cookieDetails", cookieDetails, 30);
      }
    }
  }

  public deleteCookie(cookieName: string) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  public updateCookie(name: string, value: string, daysToLiv: number) {
    this.setCookie(name, value, daysToLiv)
  }

  public getAllCookie() {
    let cookieArr = document.cookie.split(";");
    return cookieArr;
  }

  public deleteAllCookies(): void {

    // Get all the cookies associated with the current domain
    let cookies = document.cookie.split(";");

    // Loop through the cookies and delete each one
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;;
    }

  }

  public checkCookie(cookieName: string): boolean {
    // Split the document.cookie string into individual cookies
    const cookies = document.cookie.split(';');

    // Loop through the cookies and check if the given cookieName exists
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === cookieName) {
        return true; // Cookie found
      }
    }

    return false; // Cookie not found
  }

  // Note: For deleteAllCookies
  // It will not delete cookies with HttpOnly flag set, as the HttpOnly flag disables Javascript's access to the cookie.
  // It will not delete cookies that have been set with a Path value. (This is despite the fact that those cookies will appear in document.cookie, but you can't delete it without specifying the same Path value with which it was set.)


  // Secure:

  // A cookie with the Secure attribute is only sent to the server with an encrypted request over the HTTPS protocol. It's never sent with unsecured HTTP (except on localhost), which means man-in-the-middle attackers can't access it easily. Insecure sites (with http: in the URL) can't set cookies with the Secure attribute. However, don't assume that Secure prevents all access to sensitive information in cookies. For example, someone with access to the client's hard disk (or JavaScript if the HttpOnly attribute isn't set) can read and modify the information.


  public detectSecureUrl(): boolean {

    let url: string = '';
    let isHttps: boolean = false;

    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    url = location.href;

    if (urlPattern.test(url)) {
      isHttps = url.startsWith('https://');
    } else {
      isHttps = false;
    }


    return isHttps;

  }


}
