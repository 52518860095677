<div class="error-page-container">
  <div class="document-icon-container">
    <div class="d-flex">
      <div class="left-side-icon-container">
        <div class="file-doc-icon pulse-element">
          <img src="/assets/login/icons/file_doc_icon.svg" alt="" />
        </div>
        <div class="pencil-icon-24 pulse-element">
          <img src="/assets/login/icons/pencil_icon_24.svg" alt="" />
        </div>
        <div class="notepad-icon-32 pulse-element" style="animation-delay: 0.3s">
          <img src="/assets/login/icons/notepad_icon_32.svg" alt="" />
        </div>
        <div class="receipt-icon-24 pulse-element">
          <img src="/assets/login/icons/receipt_icon_24.svg" alt="" />
        </div>
        <div class="note-icon-36 pulse-element pulse-element">
          <img src="/assets/login/icons/note_icon_36.svg" alt="" />
        </div>
        <div class="news-paper-clipping-20 pulse-element" style="animation-delay: 0.7s">
          <img src="/assets/login/icons/news_paper_clipping_20.svg" alt="" />
        </div>
        <div class="news-paper-icon-48 pulse-element" style="animation-delay: 0.3s">
          <img src="/assets/login/icons/news_paper_icon_48.svg" alt="" />
        </div>
      </div>

      <div class="right-side-icon-container">
        <div class="pencil-icon-20 pulse-element" style="animation-delay: 0.4s">
          <img src="/assets/login/icons/pencil_icon_20.svg" alt="" />
        </div>
        <div class="news-paper-icon-32 pulse-element">
          <img src="/assets/login/icons/news_paper_icon_32.svg" alt="" />
        </div>
        <div class="news-paper-clipping-32 pulse-element">
          <img src="/assets/login/icons/news_paper_clipping_32.svg" alt="" />
        </div>
        <div class="receipt-icon-48 pulse-element" style="animation-delay: 0.3s">
          <img src="/assets/login/icons/receipt_icon_48.svg" alt="" />
        </div>
        <div class="note-icon-48 pulse-element">
          <img src="/assets/login/icons/note_icon_48.svg" alt="" />
        </div>
        <div class="notepad-icon-32-1 pulse-element">
          <img src="/assets/login/icons/notepad_icon_32.svg" alt="" />
        </div>
        <div class="receipt-icon-24-1 pulse-element" style="animation-delay: 0.4s">
          <img src="/assets/login/icons/receipt_icon_24.svg" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="center-container">
    <div class="speech-bubble-container animate__bounce">
      <div class="tooltip" (click)="handleTooltipClick()">
        <span class="tooltiptext welcome-title">
          We noticed inactivity and signed you out for security reasons. Please
          <span class="company-title cursor-pointer" (click)="navigateToLoginPage(); $event.stopPropagation()">log in</span>
          again
        </span>
      </div>
    </div>

    <div class="pl-24px pr-24px pt-35px">
      <img src="/assets/images/error_pages/error_401.svg" alt="" />
    </div>

    <div class="text-align-center" style="margin-top: -50px">
      <button class="btn-brand" (click)="navigateToLoginPage()">
        <img src="/assets/images/error_pages/user_icon.svg" alt="" class="mr-4px vertical-align-top" />
        Login
      </button>
    </div>

    @if (organizationName === "KlearStack") {
      <div class="bottom-logo-container">
        <div class="bottom-logo-circle">
          <img src="/assets/login/klearStack_logo_2.svg" alt="" />
        </div>
        <div class="bottom-company-title ml-8px">
          <span class="first-title">Klear</span>
          <span class="second-title">Stack</span>
        </div>
      </div>
    } @else {
      <div class="bottom-logo-container">
        <div class="bottom-logo-circle" style="background-color: white">
          <img [src]="logo_sm" alt="" class="w-35px" />
        </div>
        <div class="bottom-company-title ml-8px">
          <span class="first-title">{{ organizationName }}</span>
        </div>
      </div>
    }
  </div>
</div>
