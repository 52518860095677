import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompnayLogoTextDisplayService {

  public isCompanyLogoAndText = new BehaviorSubject<boolean>(false);

  constructor() {
    this.setIsCompanyLogoAndText(environment.companySpecificSettings.isCompanySpecificSettings);
  }

  private setIsCompanyLogoAndText(isCompanyLogoAndText: boolean): void {
    this.isCompanyLogoAndText.next(isCompanyLogoAndText);
  }
}
