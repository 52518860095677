import { LoginService } from 'src/app/modules/login/services/login.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AccessTokenInterceptor } from './access-token.interceptor';
import { appInitializer } from './app.initializer';
import { ErrorInterceptor } from './error.interceptor';
import { whiteListAPIsInterceptor } from './whitelist-flag.interceptor';
import { CookiesService } from '../services/cookies.service';

export const InterceptorsProvider = [
	// {
	// 	provide: HTTP_INTERCEPTORS,
	// 	useClass: whiteListAPIsInterceptor,
	// 	multi: true
	// },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorInterceptor,
		multi: true
	},

	{
		provide: HTTP_INTERCEPTORS,
		useClass: AccessTokenInterceptor,
		multi: true
	},
	// {
	// 	provide: APP_INITIALIZER,
	// 	useFactory: appInitializer,
	// 	multi: true,
	// 	deps: [LoginService, CookiesService]
	// },
];
